:root {
  --white-color: #eff6ff;
  --primary-color: #2563eb;
  --dark-blue-color: #172554;
  --light-blue-color: #1d4ed8;
  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-500: #3b82f6;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --neutral-900: #171717;
  --blue-950: #172554;
  --neutral-700: #404040;
  --neutral-900: #171717;
}

.hero-menu-img .content-hero {
  display: flex;
  flex: 0 0 50%;
}

.title-pt-ckb {
  background: var(--blue-900, #1e3a8a);
  color: #fff;
  padding: 16px 24px;
}

.title-pt-ckb h3 {
  color: var(--blue-50);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.content-hero img {
  width: 100%;
}

.bg-img-hero-left {
  background-image: url(../images/ckbassetbanner/homeleft.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: -15px;
}
.content-item-product {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.content-hero .overlay-text {
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  bottom: 20px;
  left: 20px;
}
.content-hero .overlay-text a {
  color: #fff;
}

.text-white {
  color: var(--white-color);
}
.appie-hero-content.appie-hero-content-4 .appie-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}

.content-item-product ul {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.content-item-product ul li {
  border-radius: 24px;
  border: 1px solid var(--blue-500);
  background: var(--blue-100);
  padding: 8px 16px;
}

.content-item-product ul li.active {
  border-radius: 24px;
  border: 1px solid var(--blue-500, #3b82f6);
  background: var(--blue-500, #3b82f6);
}
.content-item-product ul li a {
  font-size: 14px;
  text-align: center;
  align-items: center;
  display: flex;
  color: var(--neutral-900);
}
.content-item-product ul .active {
  color: var(--blue-50, #eff6ff);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
}

.menu-product {
  border-radius: 8px;
  background: var(--blue-500);
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.menu-product .title {
  border-radius: 32px;
  border: 1px solid var(--blue-50);
  padding: 8px 16px;
}
.menu-product .title h5 {
  color: var(--blue-50);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.menu-product .list-item-product {
  border-radius: 8px;
  background: var(--blue-50);
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 48px;
}
.menu-product .list-item-product .title-item-product {
  width: 15%;
  display: flex;
}

.why-choose-us .title {
  color: var(--blue-50);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
}
.why-choose-us p {
  color: var(--blue-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  margin-top: 10px;
}

.appie-logo-box img {
  width: 80px;
}

.service-desc {
  height: 150px;
}
.industries .appie-section-title {
  width: 70%;
}

.appie-download-3-area {
  border-radius: 8px;
  background: var(--blue-500, #3b82f6);
}
.area-industri {
  position: relative;
  padding: 0px 30px;
}

.area-industri .overlay-item .overlay-left {
  position: absolute;
  top: 30px;
  left: 50px;
  border-radius: 32px;
  border: 1px solid var(--blue-50);
  padding: 8px 16px;
  color: var(--blue-50);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.area-industri .overlay-item .overlay-right {
  position: absolute;
  top: 30px;
  right: 80px;
  color: var(--blue-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}
.ranah-industri {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
}
.ranah-industri .content .desc {
  padding: 24px 30px;
  height: 160px;
}

.ranah-industri .content .images img {
  height: 380px;
  object-fit: cover;
}

.ranah-industri .content .desc .title {
  color: var(--blue-950);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
}
.ranah-industri .content .desc p {
  color: var(--blue-950);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.industries .appie-title {
  color: var(--blue-50);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}

.industries .appie-section-title > h5 {
  color: var(--blue-100);
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.industries .title-desc p {
  color: var(--blue-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.appie-features-area-2.why-choose-us {
  background: var(--blue-800);
  position: relative;
}

.text-read-more {
  color: var(--blue-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.text-read-more a {
  color: var(--blue-50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.text-read-more-blue a {
  color: var(--blue-700);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}
.why-choose-us .overlay-tag {
  border-radius: 32px;
  border: 1px solid var(--blue-50);
  padding: 8px 20px;
  color: var(--blue-50);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.appie-about-area.about-ckb {
  border-radius: 8px;
  background: var(--blue-50);
  padding: 50px 24px;
}

.appie-about-area.about-ckb .appie-about-box {
  background: var(--blue-50);
}
.appie-about-area.about-ckb .appie-about-content .title {
  color: var(--blue-700);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}

.appie-about-area.about-ckb .appie-about-content p {
  color: var(--neutral-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 18.2px */
}

.appie-about-area.about-ckb .about-ckb p {
  color: var(--neutral-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 18.2px */
}

.about-ckb {
  flex-direction: column;
}

.appie-about-area.about-ckb .overlay-tag {
  border-radius: 32px;
  border: 1px solid var(--blue-950);
  padding: 8px 24px;
}
.contact-section .overlay-tag {
  border-radius: 32px;
  border: 1px solid var(--blue-950);
  padding: 8px 24px;
}
.title-contact-paragraph {
  display: flex;
  align-items: center;
}
.title-contact-paragraph p {
  color: var(--blue-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.footer-about-widget.footer-about-widget-5 h4 {
  color: var(--white-color);
}

.footer-about-widget.footer-about-widget-5 p {
  color: var(--white-color);
}

.footer-navigation.footer-navigation-5 .title {
  color: var(--white-color);
}

.footer-navigation.footer-navigation-5 ul li a {
  color: var(--white-color);
}
.footer-copyright .copyright-text p {
  color: var(--white-color);
}
.list-in-product ul li {
  list-style: initial;
  margin-left: 30px;
}

.appie-product-content h1 {
  color: var(--blue-950);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
}
.appie-product-content .number-service-product {
  color: var(--blue-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}
.appie-product-content p {
  color: var(--neutral-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.product-ckb h3 {
  overflow: hidden;
  color: var(--neutral-700);
  text-overflow: ellipsis;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
}
.product-ckb p {
  color: var(--neutral-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.product-ckb .list-in-product ul li {
  color: var(--neutral-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.w-50-percent {
  width: 50%;
}

.appie-about-page-area h3 {
  color: var(--blue-50, #eff6ff);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}

.appie-about-page-area p {
  color: var(--neutral-50, #fafafa);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.appie-about-page-area .tag-about .overlay-tag {
  border-radius: 32px;
  border: 1px solid var(--blue-50, #eff6ff);
  padding: 8px 16px;
  color: var(--blue-50, #eff6ff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* for table */
@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .bg-img-hero-left {
    margin: 15px;
  }
  .appie-hero-content.appie-hero-content-4 {
    margin-bottom: 24px;
  }
  .service-desc {
    height: auto;
  }
  .appie-download-3-area .industries {
    padding-top: 80px;
  }
  .industries .appie-section-title {
    width: 100%;
  }
  .ranah-industri .content .desc {
    padding: 14px;
    height: auto;
  }
}

/* for mobile ver  */
@media (max-width: 767px) {
  .pl-5 {
    padding-left: 0 !important;
  }
  .col-lg-7 {
    padding-right: 0px;
  }
  .menu-product .list-item-product {
    flex-direction: column;
  }
  .menu-product .list-item-product .title-item-product {
    width: 100%;
  }
  .area-industri .industries {
    flex-direction: column;
  }
  .industries .appie-section-title {
    width: 100%;
  }
  .pr-5 {
    padding-right: 0 !important;
  }
  .area-industri .overlay-item .overlay-right {
    display: none;
  }

  .pt-90 {
    padding-top: 45px !important;
  }
  /* .mt-30 {
    margin-top: 10px !important;
  } */

  .appie-features-boxes .why-choose-us {
    margin: 20px 0px;
  }
  .appie-about-area.about-ckb {
    padding: 30px 0px;
  }
  .mt-50 {
    margin-top: 20px !important;
  }
  .ml-5,
  .mr-5 {
    margin: 0 !important;
  }
  .appie-about-box .appie-about-content {
    text-align: start;
  }
  .contact-section {
    padding: 50px 30px;
  }
  .contact-form {
    margin-top: 30px;
  }
  .appie-about-area.about-ckb .overlay-tag {
    margin-left: 25px;
  }
  .bg-img-hero-left {
    height: 50vh;
  }
  .appie-product-content p {
    text-align: start;
  }
  .product-ckb p {
    text-align: start;
  }
  .product-ckb ul li {
    text-align: start;
  }
  .contact--info-area h3 {
    font-size: 30px;
  }
  .appie-visible .appie-about-page-area.industri {
    padding-top: 0;
  }
  .appie-visible .appie-about-page-area.solusi {
    padding-top: 0;
  }
  .w-50-percent {
    width: 100%;
  }
  .appie-about-page-area.industri .d-flex.justify-content-between {
    flex-direction: column;
    background: #00000094;
    padding: 50px 20px;
  }
  .appie-about-page-area.solusi .d-flex.justify-content-between {
    flex-direction: column;
    background: #00000094;
    padding: 50px 20px;
  }
  .appie-about-page-area .d-flex.justify-content-between {
    flex-direction: column;
    background: #00000094;
    padding: 50px 20px;
  }
  .contact-section {
    padding: 20px 25px;
  }
  .appie-footer-area {
    padding: 30px 20px;
  }
  .footer-navigation {
    text-align: start;
  }
  .appie-about-page-area .overlay-tag {
    background: #00000096;
    margin-top: 20px;
  }
  .service-desc {
    height: auto;
  }
  .ranah-industri .content .desc {
    height: auto;
    padding: 10px 5px;
  }
  .about-ckb {
    text-align: start;
  }
  .appie-hero-content.appie-hero-content-4 .appie-title {
    font-size: 28px;
    padding: 0px 30px;
  }
  .appie-hero-content.appie-hero-content-4 a.main-btn {
    border-radius: 4px;
    background: #2563eb;
    margin-top: 35px;
    padding: 0;
    color: #eff6ff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    width: auto;
  }
  .title-pt-ckb h3 {
    font-size: 18px;
  }
  .hero-img-right {
    padding-left: 0;
  }
  .appie-hero-content.appie-hero-content-4 {
    margin-bottom: 24px;
  }
  .service-desc {
    height: auto;
  }
  .industries .appie-section-title {
    width: 100%;
  }
  .ranah-industri .content .desc {
    padding: 14px;
    height: auto;
  }
  .appie-section-title.text-start.pr-5 {
    padding-top: 80px;
  }
}
/* end mobile res */
